import { Button, Spin, Steps, Table, notification, Form, Statistic, Row, Col, Select, Input, Result } from "antd";
import { set } from "date-fns";
import React, { useState, useEffect, useRef } from "react";
import { MdDelete } from "react-icons/md";
import { getDistributions, getExpenseParams } from "../../API/StaticGetters";
import axios from "axios";
import RelatorioPlan from "../../Components/planificacao/planModel";
import { ComponentToPrint } from "../../Components/print";
import ReactToPrint from "react-to-print";

const Planificar = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [querying, setQuerying] = useState(false);
    const [dataDist, setDataDist] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [plano, setPlano] = useState([]);
    const [categories, setCategories] = useState([]);
    const [rubrics, setRubrics] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [data, setData] = useState({})
    const printRelatorio = useRef()

    useEffect(() => {
        localStorage.setItem('title', 'Registrar Planificação');
        localStorage.setItem('type', '2');
        getData();
    }, [])

    const getData = async () => {
        setQuerying(true);
        let dados = await getDistributions(4, true);
        let types = await getExpenseParams(1);
        splitCategoriesFromRubrics(types);
        setDataDist(dados);
        setQuerying(false);
    }

    const splitCategoriesFromRubrics = (data) => {
        let cats = [];
        data.forEach(item => {
            cats.push({ value: item.categoria });
        })
        cats = cats.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
        setCategories(cats);
        setRubrics(data);
    }

    const columnsDist = [
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
            width: 150,
        },
        {
            title: 'Unidade Organica',
            dataIndex: 'unidade',
            key: 'unidade',
            render: (text, record) => (
                <p>{record?.unit?.name}</p>
            ),
        },
        {
            title: 'Período',
            dataIndex: 'periodo',
            key: 'periodo',
            width: 200,
            render: (text, record) => (
                <p>{record.period.name}</p>
            ),
        },
        {
            title: 'Valor',
            dataIndex: 'orc_value',
            key: 'orc_value',
            width: 150,
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        },
    ];

    const columnsDist2 = [
        {
            title: 'Período',
            dataIndex: 'periodo',
            key: 'periodo',
            width: 200,
            render: (text, record) => (
                <p>{record.period.name}</p>
            ),
        },
        {
            title: 'Valor',
            dataIndex: 'orc_value',
            key: 'orc_value',
            align: 'right',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        },
    ];

    const columnsPlano = [
        {
            title: 'Rubrica',
            dataIndex: 'rubric',
            key: 'rubric',
            render: (text) => text.codigo,
            width: 100,
        },
        {
            title: 'Categoria',
            dataIndex: 'category',
            key: 'category',
            render: (text) => text.value,
        },
        {
            title: 'Designação',
            dataIndex: 'rubric',
            render: (text) => text.nome,
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            width: 200,
        },
        {
            title: 'Acções',
            key: 'action',
            width: 60,
            render: (text, record, index) => (
                <span className='flex justify-center items-center'>
                    <a onClick={() => removePlan(index)}>
                        <MdDelete
                            size={17}
                            color="#d6661b"
                        />
                    </a>
                </span>
            ),
        },
    ]

    const handlePlanification = () => {
        if (dataDist.length === 0) {
            notification.error({
                title: 'Sem Distribuições',
                message: 'Não existem distribuições disponíveis para planificação'
            })
            return;
        } else {
            setCurrentStep(1);
        }
    }

    const totalDist = () => {
        let total = 0;
        dataDist.forEach(dist => {
            total += parseFloat(dist.orc_value);
        });
        return `MZN ${parseFloat(total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    }

    const totalValue = () => {
        let total = 0;
        dataDist.forEach(dist => {
            total += parseFloat(dist.orc_value);
        });
        return total;
    }

    const balance = () => {
        let total = 0;
        plano.forEach(item => {
            total += parseFloat(item.value);
        });
        return totalValue() - total;
    }

    const addPlan = (values) => {
        //first validate if the rubric is already in the plan
        let rubricExists = plano.find(item => item.rubric.codigo === values.rubrica);
        if (rubricExists) {
            notification.error({
                title: 'Rubrica Existente',
                message: 'A rubrica já foi planificada'
            });
            return;
        }
        let newPlano = [...plano];
        newPlano.push({
            category: categories.find(cat => cat.value === values.categoria),
            rubric: rubrics.find(rub => rub.codigo === values.rubrica),
            value: parseFloat(values.valor),
        });
        setPlano(newPlano);
        form.resetFields();
    }

    const removePlan = (index) => {
        let newPlano = [...plano];
        newPlano.splice(index, 1);
        setPlano(newPlano);
    }

    const resetProcess = () => {
        setData({})
        form.resetFields();
        setSelectedCategory('');
        setPlano([]);
        setCurrentStep(0);
        getData();
    }

    const confirmProcess = () => {
        if (balance() > 0.9) {
            notification.error({
                title: 'Saldo Disponível',
                message: 'O saldo disponível não foi totalmente planificado'
            });
            return;
        }
        handleSubmit()
    }

    const handleSubmit = async () => {
        setQuerying(true);
        axios.post(localStorage.getItem('url') + '/api/planification/',
            {
                unit: dataDist[0].unit.id,
                plan: plano.map(item => {
                    return {
                        rubric: item.rubric.codigo,
                        value: item.value
                    }
                }),
                distribution: dataDist.map(item => item.id),
                status: 1,
            },
            {
                headers: {
                    'Authorization': `Token ${sessionStorage.getItem('token')}`
                }
            }
        ).then(res => {
            notification.success({
                title: 'Sucesso',
                message: 'Planificação efectuada com sucesso'
            });
            setData(res.data)
            setCurrentStep(2);
        }).catch(err => {
            notification.error({
                title: 'Erro',
                message: err.response.data.message
            });
        }).finally(() => {
            setQuerying(false);
        });
    }

    return (
        <Spin spinning={querying}>
            <div className='w-full flex flex-col px-5 py-4'>
                <Steps current={currentStep} className="mb-3">
                    <Steps.Step title="Seleccione as Distribuições" />
                    <Steps.Step title="Planificação" />
                    <Steps.Step title="Conclusão" />
                </Steps>
                {currentStep === 0 && (<>
                    <p className="title-form">Distribuições Disponíveis</p>
                    <Table
                        className='custom-table mt-2'
                        size="small"
                        columns={columnsDist}
                        dataSource={dataDist}
                        loading={loading}
                        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                        locale={{
                            emptyText: 'Sem Distribuições Disponíveis',
                        }}
                        footer={() => {
                            return (
                                <div className="flex flex-row justify-between items-center w-full">
                                    <div className="w-full flex flex-row justify-end gap-3 ">
                                        <Button
                                            className="button-in"
                                            onClick={() => handlePlanification()}
                                        >
                                            Prosseguir
                                        </Button>
                                    </div>
                                </div>
                            )

                        }}
                    />
                </>)}
                {currentStep === 1 && (<>
                    <div className='notification-status-static'>
                        <p className='notification-text-static'>
                            Caro Técnico, antes de confirmar o processo, por favor verifique se os mesmos dados conferem!
                        </p>
                    </div>
                    <div className="flex flex row w-full gap-8">
                        <div className="w-1/3 flex flex-col">
                            <p className="title-form">Distribuições</p>
                            <Table
                                className='custom-table mt-3'
                                size="small"
                                columns={columnsDist2}
                                dataSource={dataDist}
                                loading={loading}
                                pagination={false}
                                locale={{
                                    emptyText: 'Sem Distribuições Disponíveis',
                                }}
                                footer={() => (
                                    <div className='flex flex-row justify-between gap-3 mt-2'>
                                        <p className='text' style={{ margin: 0 }}>Total</p>
                                        <p className="text" style={{ margin: 0 }}>{totalDist()}</p>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="w-2/3 flex flex-col">
                            <p className="title-form">Planificação</p>
                            <div className='grid grid-cols-3 gap-3 mt-3 mb-2'>
                                <Statistic
                                    title="Saldo Anterior"
                                    prefix="MZN"
                                    value={0}
                                    precision={2}
                                    valueStyle={{ color: '#3f8600' }}
                                    className="border border-gray-300 p-2 rounded-lg"
                                />
                                <Statistic
                                    title="Total a Planificar"
                                    prefix="MZN"
                                    value={totalValue()}
                                    precision={2}
                                    valueStyle={{ color: '#3f8600' }}
                                    className="border border-gray-300 p-2 rounded-lg"
                                />
                                <Statistic
                                    title="Por Planificar"
                                    prefix="MZN"
                                    value={balance()}
                                    precision={2}
                                    valueStyle={{ color: '#3f8600' }}
                                    className="border border-gray-300 p-2 rounded-lg"
                                />
                            </div>
                            <Form form={form} layout="vertical" onFinish={addPlan}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Categoria</p>}
                                            labelCol={{ span: 24 }}
                                            name="categoria"
                                            className='input'
                                            rules={[{ required: true, message: 'Por favor selecione a categoria' }]}
                                        >
                                            <Select
                                                placeholder="Selecione a categoria"
                                                allowClear
                                                className='input'
                                                onChange={(value) => setSelectedCategory(value)}
                                            >
                                                {categories.map(cat => (
                                                    <Select.Option key={cat.value} value={cat.value}>{cat.value}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Rubrica</p>}
                                            labelCol={{ span: 24 }}
                                            name="rubrica"
                                            className='input'
                                            rules={[{ required: true, message: 'Por favor selecione a rubrica' }]}
                                        >
                                            <Select
                                                placeholder="Selecione a rubrica"
                                                allowClear
                                                className='input'
                                            >
                                                {rubrics.filter(rub => rub.categoria === selectedCategory).map(rub => (
                                                    <Select.Option key={rub.id} value={rub.codigo}>{rub.nome}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<p className='label-input'>Valor</p>}
                                            labelCol={{ span: 24 }}
                                            name="valor"
                                            className='input'
                                            rules={[{ required: true, message: 'Por favor insira o valor' },
                                            {
                                                validator: (_, value) => {
                                                    if ((parseFloat(value) > balance())) {
                                                        return Promise.reject('O valor excede o saldo disponível');
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }
                                            ]}
                                        >
                                            <Input
                                                prefix="MZN"
                                                placeholder="Valor"
                                                className='input-form'
                                                type="number"
                                                min={0}
                                                style={{ borderRadius: '0px' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item style={{ textAlign: 'right' }}>
                                    <Button htmlType="submit" loading={loading} className='button-in'>
                                        Alocar
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <p className="title-form">Planificação</p>
                    <Table
                        className='custom-table mt-2'
                        size="small"
                        columns={columnsPlano}
                        dataSource={plano}
                        loading={loading}
                        pagination={false}
                        locale={{
                            emptyText: 'Sem planificação',
                        }}
                    />
                    <div className="flex flex-row justify-between mt-4">
                        <Button
                            onClick={resetProcess}
                            className='button-out'
                        >
                            Cancelar
                        </Button>
                        {plano.length > 0 &&
                            <Button
                                className='button-in'
                                onClick={confirmProcess}
                            >
                                Confirmar
                            </Button>
                        }
                    </div>
                </>)}
                {currentStep === 2 && (<>
                    <Result
                        className='p-0 mt-4'
                        status="success"
                        title={<p className='title'>Planificação Efectuada</p>}
                        subTitle={<p className='text'>A planificação foi efectuada com sucesso</p>}
                        extra={[
                            <div className="flex flex-row justify-center gap-3 mt-2">
                                <Button className='button-out' onClick={resetProcess}>
                                    Terminar
                                </Button>
                                <ReactToPrint
                                    trigger={() =>
                                        <Button
                                            className='button-in'
                                        >
                                            Imprimir Relatório
                                        </Button>
                                    }
                                    content={() => printRelatorio.current}
                                    documentTitle={'Planification Ref.' + data?.reference}
                                />
                            </div>
                        ]}
                    />
                    <div className="hidden">
                        <ComponentToPrint ref={printRelatorio}>
                            <RelatorioPlan dados={data} />
                        </ComponentToPrint>
                    </div>
                </>)}
            </div>
        </Spin>
    )
}

export default Planificar;