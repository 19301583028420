import { Input, Table } from "antd";
import { parse } from "date-fns";
import React from "react";
import dayJS from "dayjs";

const DistributionDetail = ({ dados }) => {

    const columnsReceipts = [
        {
            title: 'Receita',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            key: 'value',
            width:170,
            align:'right'
        },
    ]

    const columnsHist = [
        {
            title: 'Usuário',
            dataIndex: 'user',
            render: (text, record) => `${record?.user?.first_user_name} ${record?.user?.last_user_name}`,
            key: 'user',
        },
        {
            title: 'Acção',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <p>{record.action} {record.message? `--Mensagem: ${record.message}`: ''}</p>
            ),
        },
        {
            title: 'Data',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 130,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
    ]

    const totalArrecadado = () => {
        let list= dados?.revenue
        let total = 0
        list.forEach(receita => {
            total += parseFloat(receita.value)
        })
        return total
    }

    return (<>
        <div className="w-full flex flex-col">
            <div className='grid grid-cols-2 gap-4 w-full mt-2 mb-2'>
                <div className='flex flex-col'>
                    <p className='label-see'>Unidade Organica</p>
                    <Input
                        className='input-see'
                        value={dados?.unit?.name}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Período</p>
                    <Input
                        className='input-see'
                        value={dados?.period?.name}
                        disabled
                    />
                </div>
            </div>
            <p className="title-form">Receitas Arrecadadas</p>
            <Table
                className='custom-table mt-2 mb-0'
                size="small"
                columns={columnsReceipts}
                dataSource={dados?.revenue}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                locale={{
                    emptyText: 'Sem receitas arrecadadas no período'
                }}
                footer={()=>(
                <div className="flex flex-row justify-between w-full">
                    <p className="text" style={{margin:0}}>TOTAL</p>
                    <p className="text" style={{margin:0, width:160, textAlign:'right'}}>{`MZN ${parseFloat(totalArrecadado()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</p>
                </div>
                )}
            />
            <p className="title-form">Plano de Distribuição</p>
            <div className='grid grid-cols-3 gap-4 w-full mt-2 mb-2'>
                <div className='flex flex-col'>
                    <p className='label-see'>Orçamento de Funcionamento</p>
                    <Input
                        className='input-see'
                        value={`MZN ${parseFloat(dados?.orc_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>FDI</p>
                    <Input
                        className='input-see'
                        value={`MZN ${parseFloat(dados?.fdi_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Apoio a Actividades Despor...</p>
                    <Input
                        className='input-see'
                        value={`MZN ${parseFloat(dados?.apo_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
                        disabled
                    />
                </div>
            </div>
            <p className="title-form">Histórico</p>
            <Table
                className='custom-table mt-2 mb-0'
                size="small"
                columns={columnsHist}
                dataSource={dados?.logs}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                locale={{
                    emptyText: 'Sem histórico'
                }}
            />
        </div>
    </>)
}

export default DistributionDetail;